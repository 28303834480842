html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 230, 223, 1);
}

#root {
  height: 100%;
}
